import React from "react"
import Img from "gatsby-image"
import ProgramModal from "./TeamModal"

import styles from "./OurTeam.module.css"

function Modal(props) {
  const [modalShow, setModalShow] = React.useState(false)

  return (
    <>
    <div style={{ width: "100%", height: "100%", position: "relative", cursor: "pointer" }}
    onClick={() => setModalShow(true)} role="button" onKeyDown={() => setModalShow(true)} tabIndex={0}
    >
      <div style={{
          position: 'relative',
      }}>  <Img
            fluid={props.fluid}
            alt={props.alt}
            className={props.className}
            // style={{boxShadow: '3px 3px 3px #00000029'}}
        />
        
        <div
            style={{ position: 'absolute', bottom: 0, paddingLeft: '20px'}}
            className={`${styles.gridImageContainer}`}>
                <p className={styles.imageLinkText}>
                    {props.imageLinkText}<br/>
                   <span className={styles.imageLinkTitle}> {props.imageLinkTitle} </span><br />
                   + Read Bio
                </p>
              </div>
              </div>
        </div>       
      <ProgramModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        modalTitle={props.modalTitle}
        modalContent={props.modalContent}
        teamMemberPhoto={props.teamMemberPhoto}
      />
    </>
  )
}

export default Modal