import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"

import '@fortawesome/fontawesome-svg-core/styles.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons"

import styles from "../components/OurTeamPage/OurTeam.module.css"
import OurTeamHero from '../components/OurTeamPage/OurTeamHero'
import HoverCardBlock from "../components/HoverCardBlock/HoverCardBlock"

import Partners from "../components/Homepage/Partners"
import RealPeople from "../components/Homepage/RealPeople"
import CareTeam from "../components/OurTeamPage/CareTeam"
import BlogBlock from "../components/OurTeamPage/BlogBlock"


const Overview = ({ element }) => {
    // Query for HoverCardBlock image
    const data = useStaticQuery(graphql`
    query {
    ourTeamHoverCard: file(relativePath: { eq: "our-team-hovercard.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    }
`)
  return (
  <Layout>
    <SEO title="The Symphony Team" />
    <OurTeamHero>
    <Container className="hero-container">
          <Row className="hero-flex-row">
            <Col style={{marginBottom: '50px'}}>
            <h1 className={styles.heroHeadline}>
              We are driving change for seniors.
            </h1>
            <h2 className="hero-subhead">
            Symphony sees all seniors experiencing greater independence and meaningful enjoyment throughout their lives.
            </h2>
            <h2 className="hero-subhead" style={{
              fontWeight: '200'
            }}>Symphony is revolutionizing aging-in-place using our compassion, technology, and deep understanding of seniors’ needs.</h2>
            </Col>
          </Row>
        </Container>
    </OurTeamHero>
    <Container>
      <Row>
        <Col>
        <HoverCardBlock
          fluid={data.ourTeamHoverCard.childImageSharp.fluid}
          alt="Let our experts guide you"
          // style={{alignSelf: 'flex-end', marginBottom: '-40px'}}
    >
      <div>
      <p className={styles.hoverCardTitle}>
        Harmony Through Core Values
      </p>
      <p className={styles.hoverCardText}>Symphony believes in achieving the very best for seniors as they remain at home. We bring together the best in the industry to plan, implement and care for seniors, revolutionizing the way they communicate with loved ones and care partners.</p>
      <p className={styles.hoverCardText}>Our Team:</p>
      <div className={styles.hoverCardList}>
      <FontAwesomeIcon
        icon={faCheckCircle} 
        className={styles.hoverCardIcon}
        />
          Orchestrates client and team harmony
      </div>
      <div className={styles.hoverCardList}>
      <FontAwesomeIcon
        icon={faCheckCircle} 
        className={styles.hoverCardIcon}
        />
        Delivers value courageously
      </div>
      <div className={styles.hoverCardList}>
      <FontAwesomeIcon
        icon={faCheckCircle} 
        className={styles.hoverCardIcon}
        />
        Always stays positive
      </div>
      <div className={styles.hoverCardList}>
      <FontAwesomeIcon
        icon={faCheckCircle} 
        className={styles.hoverCardIcon}
        />
        Exceeds expectations
        </div>
        </div>
      </HoverCardBlock>
        </Col>
      </Row>
    </Container>

    <CareTeam />

    <Partners />
    <RealPeople />
    <Container>
      <Row>
        <Col>
            <BlogBlock />
        </Col>
      </Row>
    </Container>
  </Layout>
  )
}

export default Overview
