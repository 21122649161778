import React from "react"
import { Modal, Row, Col } from "react-bootstrap"
import styles from './OurTeam.module.css'
//import "../about.css"

function AboutTeamModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        
      </Modal.Header>
      <Row>
          <Col className="text-center">
            <div>
              <img
                src={props.teamMemberPhoto}
                style={{ maxWidth: "250px" }}
                alt={props.teamMember}
                className={styles.round}
              />
            </div>
          </Col>
          </Row>
          <Row>
          <Col className="text-center">
            <h3>{props.teamMember}</h3>
            <h4 className={styles.modalTitle}>{props.modalTitle}</h4>
          </Col>
        </Row>
      <Modal.Body>
        {props.modalContent}
      </Modal.Body>
    </Modal>
  )
}

export default AboutTeamModal