import React from 'react';
import { graphql, useStaticQuery } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import styles from './OurTeam.module.css'
import TeamModalButton from './TeamModalButton'

import JohnValiantePhoto from '../../images/John-Valiante.jpg'
import CaroleLynneRichardsonPhoto from '../../images/Carole-Lynne-Richardson.jpg'
import SueParksPhoto from '../../images/Sue-Parks.png'
import ArthurPelbergPhoto from '../../images/Arthur-Pelberg.png'
import LaneGValiantePhoto from '../../images/Lane-G-Valiante.png'
import JeffPotterPhoto from '../../images/Jeff-Potter.jpg'

import { JohnValiante, CaroleLynneRichardson, SueParks, ArthurPelberg, LaneGValiante, JeffPotter } from './TeamBios'

const CareTeam = () => {
    const data = useStaticQuery(graphql `
        query {
         johnValiante: file(relativePath: { eq: "John-Valiante.jpg"}) {
             childImageSharp {
                 fluid(maxWidth: 270, quality: 80) {
                     ...GatsbyImageSharpFluid
                 }
             }
         }
         jeffPotter: file(relativePath: { eq: "Jeff-Potter.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 270, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        caroleLynne: file(relativePath: { eq: "Carole-Lynne-Richardson.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 270, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        sueParks: file(relativePath: { eq: "Sue-Parks.png"}) {
            childImageSharp {
                fluid(maxWidth: 270, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        arthurPelberg: file(relativePath: { eq: "Arthur-Pelberg.png"}) {
            childImageSharp {
                fluid(maxWidth: 270, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        laneGValiante: file(relativePath: { eq: "Lane-G-Valiante.png"}) {
            childImageSharp {
                fluid(maxWidth: 270, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        }
    `)

    return (
        <>
        <Container>
            <Row>
                <Col>
                    <h1 className={`${styles.uniqueCare} mb-4`}>Our Symphony Care Team</h1>
                </Col>
            </Row>
        </Container>
        <Container>
            <Row>
                <Col sm={4} className="my-2">
                <TeamModalButton 
            fluid={data.johnValiante.childImageSharp.fluid}
            alt="John Valiante"
            className={`${styles.round}`}
            imageLinkText="John Valiante"
            imageLinkTitle="President &amp; Founder"
            modalTitle="John Valiante, President &amp; Founder"
            modalContent={JohnValiante}
            teamMember="John Valiante, President &amp; Founder"
            teamMemberPhoto={JohnValiantePhoto}
        />
                </Col>
                <Col sm={4} className="my-2">
                <TeamModalButton 
            fluid={data.caroleLynne.childImageSharp.fluid}
            alt="Carol-Lynne Richardson"
            className={styles.round}
            imageLinkText="Carol-Lynne Richardson"
            imageLinkTitle="Client Experience Advocate"
            modalTitle="Carole-Lynne Richardson, Client Experience Advocate"
            modalContent={CaroleLynneRichardson}
            teamMemberPhoto={CaroleLynneRichardsonPhoto}
        />
                </Col>
                <Col sm={4} className="my-2">
                <TeamModalButton 
            fluid={data.sueParks.childImageSharp.fluid}
            alt="Sue Parks"
            className={styles.round}
            imageLinkText="Sue Parks, RN, MS"
            imageLinkTitle="Clinical Advisor"
            teamMemberPhoto={SueParksPhoto}
            modalTitle="Sue Parks, RN, MS, Clinical Adivsor"
            modalContent={SueParks}
        />
                </Col>
                </Row>
                <Row>
                <Col sm={4} className="my-2">
                <TeamModalButton 
            fluid={data.arthurPelberg.childImageSharp.fluid}
            alt="Arthur Pelberg"
            className={styles.round}
            imageLinkText="Arthur Pelberg, MD, MPA"
            imageLinkTitle="Medical Advisor"
            teamMemberPhoto={ArthurPelbergPhoto}
            modalTitle="Arthur Pelberg, MD, MPA, Medical Adivsor"
            modalContent={ArthurPelberg}
        />
                </Col>
                
                <Col sm={4} className="my-2">
                <TeamModalButton 
            fluid={data.laneGValiante.childImageSharp.fluid}
            alt="John Valiante"
            className={`${styles.round}`}
            imageLinkText="Lane G. Valiante"
            imageLinkTitle="Marketing Strategy"
            modalTitle="Lane G. Valiante, Marketing Strategy"
            modalContent={LaneGValiante}
            teamMember="Lane G. Valiante, Marketing Strategy"
            teamMemberPhoto={LaneGValiantePhoto}
        />
                </Col>
                <Col sm={4} className="my-2">
                <TeamModalButton 
            fluid={data.jeffPotter.childImageSharp.fluid}
            alt="Jeff Potter"
            className={styles.round}
            imageLinkText="Jeff Potter"
            imageLinkTitle="Administrator"
            teamMemberPhoto={JeffPotterPhoto}
            modalContent={JeffPotter}
            modalTitle="Jeff Potter, Administrator"
            teamMember="Jeff Potter, Administrator"

        />
                </Col>
                
            </Row>
        </Container>
        </>
    )
}

export default CareTeam;